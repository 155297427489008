import { Grid } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloseIcon from '@material-ui/icons/Close'
import ForumIcon from '@material-ui/icons/Forum'
import moment from 'moment'
import React, { Component } from 'react'
import Modal1 from 'react-awesome-modal'
import { Button, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import customStyle from '../../../components/healthqn/CSS/stylesheet_UHS'
import { getAnnouncemetDetails, getEducationDetails } from '../../ApiCall'
import CommonLoader from '../../CommonLoader'
import AnnouncementNotification from '../../Images/notification/notification_announcement_icon.svg'
import NoticeIcon from '../../Images/notification/notification_notice_icon.svg'
import MobileFooter from '../../MobileScreen/MobileFooter'
import CommonFooter from '../CommonFooter'
import Header from '../Header'
import KCLogo from '../../Images/KC_logo.png'
import UHFLogo from '../../Images/UHF_logo.png'
import AlykaLogo from '../../Images/Alyka_logo.png'

const CrudButton = withStyles(customStyle.crudBtn)(Fab)

const NextButton = withStyles(customStyle.viewBtn)(Button)

export default class AnnouncementNotificationCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataUnread: [],
            dataRead: [],
            noticeData: [],
            data: [],
            message: null,
            loader: false,
            date: '',
            page: 0,
            rowsPerPage: 20,
            rowCount: 0,
            createdDate: [],
            tempNotAvailableModal: false,
            content: '',
            header: '',
            publishDate: '',
            noticeModal: false,
            URL: null,
            Type: null,
            videoUrl: null,
            isShowModal: false,
        }
    }
    componentDidMount() {
        this.getAnnouncemet()
        this.loadQueryParams()
        document.addEventListener('myevent1', event => {
            this.setState({ page: 0 }, () => this.getNotification())
        })
    }

    loadQueryParams = () => {
        if (localStorage.getItem('openModal') == true) {
            this.openTempModal()
        }
    }

    getAnnouncemet = () => {
        this.setState({
            loader: true
        })
        getEducationDetails(this.state.page).then(res => {
            if (res.data && res.data.response) {
                this.setState({
                    data: res.data.response.notificationDetails,
                    rowCount: res.data.response.notificationDetails.length,
                    loader: false
                })
            } else {
                this.setState({ message: 'Health and Wellness details not available !', loader: false })
            }
        })
    }

    onSectionClick = (title, url) => {
        window.open(url)
    }

    getDateInUTC = (date, getInMillisecs) => {
        if (date) {
            let newDateTime = new Date(date)

            return new Date(newDateTime)
        }

        return date
    }

    dateformat(date) {
        const enrollmentDate = this.getDateInUTC(date, true)
        let nowdate = this.getDateInUTC(new Date(), true)

        var day = moment(enrollmentDate).format('DD')

        var mon = moment(enrollmentDate).format('MM')

        var year = moment(enrollmentDate).format('YYYY')

        var date = mon + '/' + day + '/' + year

        var todayDate = moment(nowdate).format('MM/DD/YYYY')
        let hr = moment(enrollmentDate).format('hh')

        if (date !== todayDate) {
            return moment(date).format('MMM DD, YYYY')
        } else {
            if (hr == '00') {
                return moment(enrollmentDate).format('m') + 'mins'
            }
            if (hr > 12) {
                return moment(enrollmentDate).format('hh:mm A')
            } else {
                return moment(enrollmentDate).format('hh:mm A')
            }
        }
    }

    publishDateformat(date) {
        const enrollmentDate = this.getDateInUTC(date, true)
        let nowdate = this.getDateInUTC(new Date(), true)

        var day = moment(enrollmentDate).format('DD')

        var mon = moment(enrollmentDate).format('MM')

        var year = moment(enrollmentDate).format('YYYY')

        var date = mon + '/' + day + '/' + year

        var todayDate = moment(nowdate).format('MM/DD/YYYY')
        let hr = moment(enrollmentDate).format('hh')

        return moment(date).format('MMMM DD, YYYY')
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage }, () => this.getNotification())
    }

    onClickUpdateNotificationStatus = (title, msg, url, type, date) => {
        this.setState({
            noticeModal: true,
            content: msg,
            header: title,
            publishDate: date,
            URL: url,
            Type: type
        })
    }

    openTempModal = () => {
        this.setState({
            sidebarOpen: false,
            tempNotAvailableModal: true
        })
    }

    closeTempModal = () => {
        localStorage.removeItem('OpenModal')
        this.setState({ tempNotAvailableModal: false })
    }

    goBack = () => {
        this.props.history.push('/')
    }
    render() {
        console.log(this.state.data);
        return (
            <div className="progInfoMainWrapper">
                {this.state.loader ? <CommonLoader /> : null}
                <div className="mobileViewHeader">
                    <div className="mobile_header">
                        <Link to="/">
                            <ArrowBackIcon style={{ width: '24px', height: '24px', color: '#ffffff' }} />
                        </Link>
                        <div className="mobile_header_title">Member Education Panel </div>
                    </div>
                </div>

                <div className="webHeader">
                    <Header name={'Member Education Panel'} />
                </div>
                <div className="container progInfoContainer">
                    <span className="Back_page" onClick={this.goBack}>
                        <ArrowBackIcon style={{ width: '24px', height: '24px', color: ' #543379', marginRight: '5px' }} onClick={this.goBack} />
                        BACK
                    </span>
                    <div className="commonWrap">
                        <div className="myNotificationSection_mob">
                            <h2 className="progInfoTitle">Member Education Panel</h2>

                            <TableContainer component={Paper} style={{ height: '50vh', overflow: 'auto' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                        {this.state.data[0] ? (
                                            this.state.data.map((data, index) => {
                                                return (
                                                  <TableRow key={index}>
                                                    <TableCell class="notification_details_mainDiv">
                                                      <>
                                                        <div>
                                                          {data.logo == 'UHF' ? (
                                                            <img
                                                              src={UHFLogo}
                                                              class="Notification_category_img"
                                                              style={{ width: '2.7vw' }}
                                                            />
                                                          ) : data.logo == 'Alyka Health' ? (
                                                            <img
                                                              src={AlykaLogo}
                                                              class="Notification_category_img"
                                                              style={{ width: '2.7vw' }}
                                                            />
                                                          ) : data.logo == "Kelly's Choice" ? (
                                                            <img
                                                              src={KCLogo}
                                                              class="Notification_category_img"
                                                              style={{ width: '2.7vw' }}
                                                            />
                                                          ) : null}
                                                        </div>
                                                        <div
                                                          class="notification_category_Message_details"
                                                          onClick={() => this.onSectionClick(data.title, data.urlPath)}
                                                        >
                                                          <p style={{ color: '#4e4e4e' }}>
                                                            {data.title}
                                                            <span className="notification_details_Date">
                                                              {this.dateformat(data.createdDate)}
                                                            </span>
                                                          </p>
                                                          <div class="notification_category_label_details">{data.message}</div>
                                                        </div>
                                                      </>
                                                    </TableCell>
                                                  </TableRow>
                                                )
                                            })
                                        ) : (
                                            <TableRow>
                                                <TableCell align="center" class="notification_details_mainDiv" style={{ justifyContent: 'center', fontWeight: 500, height: 150, fontSize: 18 }}>
                                                    Watch this space for upcoming articles and videos on health and wellness.
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {this.state.data ? (
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    component="div"
                                    count={this.state.rowCount}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            ) : null}
                        </div>
                        <div className="progInfoFooter" style={{ paddingTop: '0px' }}>
                            <div className="row">
                                <div className="col-md-12 dpFlex">
                                    <div className="footerText" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <div>
                                            <p>Need help?</p>
                                            <p>Chat with a Universal Health Fellowship Representative</p>
                                            <p>
                                            {`or call ${(localStorage.getItem('CLIENT_ID') == '5742' || localStorage.getItem('CLIENT_ID') == '4599') ? '888-308-0024':'888-366-6243'}`}
                                                {/* {(() => {
                                                    var client_id_locale = localStorage.getItem('CLIENT_ID')
                                                    if (client_id_locale == '3211' || client_id_locale == '3098') {
                                                        return '(888) 366 6243'
                                                    } else {
                                                        return '(877)-987-1233'
                                                    }
                                                })()} */}
                                            </p>
                                        </div>
                                        <div style={{ marginLeft: 12 }} className="">
                                            <CrudButton
                                                className={'purechat-button-expand'}
                                                color="primary"
                                                onClick={() => window.pureChat()}
                                                aria-label="add"
                                                style={customStyle.CommonChatBtn}
                                            >
                                                <ForumIcon />
                                            </CrudButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="webFooter">
                    <CommonFooter />
                </div>
                <div className="mobileFooter">
                    <MobileFooter />
                </div>

                <Modal size='xl' show={this.state.isShowModal} centered className="ChangPayModal paymentWalletModal" backdrop="static">
                    <Modal.Body style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                        <Grid container direction="row" justify="space-between" alignItems="flex-start" style={{ flexWrap: 'nowrap' }}>
                            <span class="Change-Payment-Method" style={{ lineHeight: '1.3' }}>
                                Video Title
                            </span>
                            <IconButton aria-label="close" onClick={() => this.setState({ isShowModal: false })} style={{ marginTop: '-13px' }}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                        <div>
                            <iframe
                                src={this.state.videoUrl}
                                title="Common Module"
                                onLoad={() => {
                                    this.setState({
                                        isShowModal: true
                                    })
                                }}
                                style={{
                                    width: '725px',
                                    height: '470px',
                                    border: 'none',
                                    margin: 'auto'
                                }}
                            ></iframe>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export const getQueryParams = () => {
    let url = window.location.href

    let queryObj = {}

    if (url !== undefined && url !== null && url.split('?').length > 1) {
        let queryString1 = url.split('?openModal=')[1]
        let queryParams = new URLSearchParams(queryString1)

        if (queryString1 == 'true') {
            localStorage.setItem('openModal', true)
        }
    }
}
